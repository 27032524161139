import { combine, createEvent, createStore } from 'effector'
import * as smApi from '@gmini/sm-api-sdk'

import { useStore } from 'effector-react'

import {
  FormattedSubscribeConfigs,
  UnsubscribeParams,
  changeRecipientModuleConfigParams,
  changeSubscriberConfigParams,
} from '../types/LkTypes'
import { formattedSubscribeConfigs } from '../helpers'
import {
  CompaniesService,
  IssuesService,
  RolesService,
  UsersService,
} from '../../common'

export function createLkService(
  usersService: UsersService,
  rolesService: RolesService,
  companiesService: CompaniesService,
  issuesService: IssuesService,
) {
  const fetchAttributeValues = smApi.Attribute.fetchAttributeValues.createContext()
  const fetchAttributeValuesPending$ = fetchAttributeValues.pending$
  const fetchDigest = smApi.Omni.fetchDigest.createContext()
  const fetchUser = smApi.Omni.fetchUser.createContext()
  const fetchRecipientModuleConfigs = smApi.Omni.fetchRecipientModuleConfigs.createContext()
  const fetchSubscriberConfigs = smApi.Omni.fetchSubscriberConfigs.createContext()
  const fetchModulesTaxonomy = smApi.Omni.fetchModulesTaxonomy.createContext()
  const fetchUserPending$ = fetchUser.pending$
  const fetchDigestPending$ = fetchDigest.pending$
  const fetchRecipientModuleConfigsPending$ =
    fetchRecipientModuleConfigs.pending$
  const fetchSubscriberConfigsPending$ = fetchSubscriberConfigs.pending$
  const fetchModulesTaxonomyPending$ = fetchModulesTaxonomy.pending$
  const deleteSavedFilterLocal = createEvent<UnsubscribeParams>()

  const updateUser = smApi.Omni.updateUser.createContext()
  const updateDigest = smApi.Omni.updateDigest.createContext()
  const updateRecipientModuleConfig = smApi.Omni.updateRecipientModuleConfig.createContext()
  const updateSubscriberConfig = smApi.Omni.updateSubscriberConfig.createContext()
  const linkUserTg = smApi.Omni.linkUserTg.createContext()
  const unlinkUserTg = smApi.Omni.unlinkUserTg.createContext()
  const bulkUpdateRecipientModuleConfigs = smApi.Omni.bulkUpdateRecipientModuleConfigs.createContext()
  const bulkUpdateSubscriberConfigs = smApi.Omni.bulkUpdateSubscriberConfigs.createContext()
  const updateDigestLocal = createEvent<smApi.Omni.DigestParams>()
  const updateRecipientModuleConfigLocal = createEvent<changeRecipientModuleConfigParams>()
  const updateSubscriberConfigLocal = createEvent<changeSubscriberConfigParams>()
  const updateAllRecipientModuleConfigsLocal = createEvent<{
    field: 'allowEmail' | 'allowTelegram'
    value: boolean
  }>()
  const updateAllSubscribeConfigsLocal = createEvent<{
    field: 'allowEmail' | 'allowTelegram'
    value: boolean
  }>()

  const sendNowDigest = smApi.Omni.sendNowDigest.createContext()

  const user$ = createStore<smApi.Omni.UserRes | null>(null)
    .on(fetchUser.doneData, (_, result) => result)
    .on(updateUser.doneData, (_, result) => result)

  const digest$ = createStore<smApi.Omni.DigestRes | null>(null)
    .on(fetchDigest.doneData, (_, result) => result)
    .on(updateDigest.doneData, (state, { nextDeliveryDatetime }) => {
      if (!state) {
        return state
      }

      return {
        ...state,
        nextDeliveryDatetime,
      }
    })
    .on(updateDigestLocal, (state, updateData) => {
      if (!state) {
        return state
      }

      return {
        ...state,
        ...updateData,
      }
    })

  const recipientModuleConfigs$ = createStore<smApi.Omni.RecipientModuleConfigsRes | null>(
    null,
  )
    .on(fetchRecipientModuleConfigs.doneData, (_, result) => result)
    .on(updateRecipientModuleConfigLocal, (state, updateData) => {
      if (Array.isArray(state)) {
        return state.map(config => {
          if (config.id === updateData.id) {
            return { ...config, ...updateData }
          }
          return config
        })
      }
      return state
    })
    .on(updateAllRecipientModuleConfigsLocal, (state, { field, value }) =>
      state?.map(config => ({
        ...config,
        [field]: value,
      })),
    )

  const subscriberConfigs$ = createStore<smApi.Omni.SubscriberConfigsRes>(
    [],
  ).on(fetchSubscriberConfigs.doneData, (_, result) => result)

  const uniqueUserIds$ = createStore<string[]>([]).on(
    subscriberConfigs$,
    (_, subscriberConfigs) => {
      const uniqueUserIds = subscriberConfigs.reduce((acc, { attributes }) => {
        attributes.forEach(attr => {
          if (attr.source === 'USER' && !acc.includes(String(attr.value))) {
            acc.push(String(attr.value))
          }
        })
        return acc
      }, [] as string[])

      return uniqueUserIds
    },
  )

  const uniqueAttributeIds$ = createStore<number[]>([]).on(
    subscriberConfigs$,
    (_, subscriberConfigs) => {
      const uniqueAttributeIds = subscriberConfigs.reduce(
        (acc, { attributes }) => {
          attributes.forEach(attr => {
            if (
              attr.attribute === 'issue.attributeValueIds' &&
              !acc.includes(Number(attr.value))
            ) {
              acc.push(Number(attr.value))
            }
          })
          return acc
        },
        [] as number[],
      )

      return uniqueAttributeIds
    },
  )

  const attributeById$ = createStore<Record<number, smApi.Attribute>>({}).on(
    fetchAttributeValues.doneData,
    (_state, result) =>
      result.attributeValues.reduce(
        (acc: Record<string, smApi.Attribute>, item) => {
          acc[item.id] = item

          return acc
        },
        {},
      ),
  )

  const modulesTaxonomy$ = createStore<smApi.Omni.ModulesTaxonomyRes>({}).on(
    fetchModulesTaxonomy.doneData,
    (_, result) => result,
  )

  const formattedSubscribeConfigs$ = createStore<FormattedSubscribeConfigs>([])
    .on(
      combine({
        subscriptions: subscriberConfigs$,
        filters: issuesService.savedFilterById$,
        modulesTaxonomy: modulesTaxonomy$,
        statuses: issuesService.statusById$,
        users: usersService.userById$,
        companies: companiesService.companyById$,
        roles: rolesService.roleById$,
        attributes: attributeById$,
      }),
      (_state, result) => formattedSubscribeConfigs(result),
    )
    .on(updateSubscriberConfigLocal, (state, updateData) => {
      if (Array.isArray(state)) {
        return state.map(config => {
          if (config.id === updateData.id) {
            return { ...config, ...updateData }
          }
          return config
        })
      }

      return state
    })
    .on(updateAllSubscribeConfigsLocal, (state, { field, value }) =>
      state?.map(config => ({
        ...config,
        [field]: value,
      })),
    )
    .on(deleteSavedFilterLocal, (state, { publicId }) =>
      state.filter(config => config.publicId !== publicId),
    )

  function useUser() {
    return useStore(user$)
  }

  function useDigest() {
    return useStore(digest$)
  }

  function useRecipientModuleConfigs() {
    return useStore(recipientModuleConfigs$)
  }

  function useUniqueUserIds() {
    return useStore(uniqueUserIds$)
  }

  function useUniqueAttributeIds() {
    return useStore(uniqueAttributeIds$)
  }

  function useFormattedSubscribeConfigs() {
    return useStore(formattedSubscribeConfigs$)
  }

  return {
    deleteSavedFilterLocal,
    fetchUser,
    fetchDigest,
    fetchRecipientModuleConfigs,
    fetchSubscriberConfigs,
    fetchModulesTaxonomy,
    fetchAttributeValues,
    fetchUserPending$,
    fetchDigestPending$,
    fetchRecipientModuleConfigsPending$,
    fetchSubscriberConfigsPending$,
    fetchModulesTaxonomyPending$,
    fetchAttributeValuesPending$,
    updateUser,
    updateDigest,
    updateDigestLocal,
    updateRecipientModuleConfig,
    updateSubscriberConfig,
    bulkUpdateRecipientModuleConfigs,
    bulkUpdateSubscriberConfigs,
    updateRecipientModuleConfigLocal,
    updateSubscriberConfigLocal,
    updateAllRecipientModuleConfigsLocal,
    updateAllSubscribeConfigsLocal,
    sendNowDigest,
    linkUserTg,
    unlinkUserTg,
    useUser,
    useDigest,
    useRecipientModuleConfigs,
    useUniqueUserIds,
    useUniqueAttributeIds,
    useFormattedSubscribeConfigs,
  }
}
