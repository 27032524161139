export const calculateCheckboxStates = <
  T extends { allowEmail: boolean; allowTelegram: boolean }
>(
  configs: T[],
) => {
  const initialState = {
    allEmailsChecked: true,
    anyEmailChecked: false,
    allTelegramsChecked: true,
    anyTelegramChecked: false,
  }

  const result = configs.reduce((acc, config) => {
    acc.allEmailsChecked = acc.allEmailsChecked && config.allowEmail
    acc.anyEmailChecked = acc.anyEmailChecked || config.allowEmail
    acc.allTelegramsChecked = acc.allTelegramsChecked && config.allowTelegram
    acc.anyTelegramChecked = acc.anyTelegramChecked || config.allowTelegram
    return acc
  }, initialState)

  return {
    allEmailsChecked: result.allEmailsChecked,
    emailIndeterminate: result.anyEmailChecked && !result.allEmailsChecked,
    allTelegramsChecked: result.allTelegramsChecked,
    telegramIndeterminate:
      result.anyTelegramChecked && !result.allTelegramsChecked,
  }
}
