import styled from 'styled-components'

export const HeadStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-right: 24px;
`

export const HeadRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const HeadTitle = styled.h1`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 20px;
  font-weight: 400;
  color: var(--main-color);
`

export const HeadNotificationsBtn = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const HeadNotificationsName = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: var(--dark-blue-color-2);
`

export const HeadDsc = styled.p`
  font-size: 12px;
  color: var(--main-color);
`

export const HeadTelegram = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const HeadTelegramLink = styled.a`
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 6px 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 167%;
  color: var(--blue-color);
  text-decoration: none;
`
export const HeadTelegramLogout = styled.button`
  font-size: 12px;
  color: var(--main-color);
  border: none;
  padding: 6px 4px;
  background: transparent;
`

export const HeadTelegramRow = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`

export const HeadTelegramWarning = styled.div`
  display: flex;
  align-items: center;
  column-gap: 6px;
  font-weight: 400;
  font-size: 11px;
  line-height: 145%;
  text-align: center;
  color: #353b60;
  border-radius: 20px;
  background-color: #fdedd3;
  height: 24px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 4px;
  padding-right: 12px;
`
