import styled from 'styled-components'

export const RecipientModuleConfigTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.01em;
  color: var(--dark-blue-color);
  padding-top: 9px;
  padding-bottom: 19px;
`

export const RecipientModuleConfigSiteName = styled.div`
  font-size: 13px;
  line-height: auto;
  letter-spacing: 0.01em;
  color: var(--dark-blue-color);
`
export const RecipientModuleConfigSiteDescription = styled.div`
  margin-top: 6px;
  font-size: 12px;
  line-height: auto;
  letter-spacing: 0.01em;
  color: var(--dark-blue-color);
  opacity: 0.6;
`
