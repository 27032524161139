import * as smApi from '@gmini/sm-api-sdk'

import { DAYS_OF_WEEK_SHORT, DAYS_OF_WEEK_FULL } from '../../common'

export const formatActiveDays = (digest: smApi.Omni.DigestRes) => {
  const activeDays = Object.entries(digest)
    .filter(([key, value]) => DAYS_OF_WEEK_SHORT[key] && value)
    .map(([key]) => ({
      short: DAYS_OF_WEEK_SHORT[key],
      full: DAYS_OF_WEEK_FULL[key],
    }))

  if (activeDays.length === 7) {
    return 'день'
  }

  switch (activeDays.length) {
    case 1:
      return activeDays[0].full
    case 2:
      return activeDays.map(day => day.short).join(' и ')
    default:
      return activeDays.length > 2
        ? `${activeDays
            .slice(0, -1)
            .map(day => day.short)
            .join(', ')} и ${activeDays[activeDays.length - 1].short}`
        : 'Нет выбранных дней'
  }
}
