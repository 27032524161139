import { NotificationsPage } from '../Lk'
import { UnsubscribePage } from '../Unsubscribe'

export type Page = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.FC<any>
  path: string
}

export const pages: Page[] = [
  {
    component: UnsubscribePage,
    path: '/subscriptions/:id/unsubscribe/',
  },
  {
    component: NotificationsPage,
    path: '/lk/notifications/',
  },
]
