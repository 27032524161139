import styled from 'styled-components'

export const AttributesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 24px;
`

export const Attribute = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`

export const AttributeLabel = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 193%;
  letter-spacing: 0.01em;
  color: #353b60;
  min-width: 120px;
`

export const AttributeValue = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 193%;
  letter-spacing: 0.01em;
  color: #00003d;
`

export const AttributeValueBlocks = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`

export const AttributeValueBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: #00003d;
  padding: 2.5px 6px;
  background-color: #ebebef;
  border-radius: 4px;
`

export const AttributeStatuses = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

type AttributeStatus = {
  separatorColor: string
}

export const AttributeStatus = styled.div<AttributeStatus>`
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 400;
  font-size: 12px;
  line-height: 175%;
  letter-spacing: 0.01em;
  color: var(--dark-blue-color-2);

  &:before {
    content: '';
    border-radius: 4px;
    width: 4px;
    height: 18px;
    background: ${({ separatorColor }) => separatorColor};
  }
`
