import React from 'react'
import * as smApi from '@gmini/sm-api-sdk'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.locale('ru')

import { CalendarSimple } from '@gmini/ui-kit/lib/icons'

import { tableCheckboxColors } from '../TableCheckboxColors'
import { changeModuleParams } from '../../../types/LkTypes'
import { SHORT_DISPLAY_DATE_FORMAT } from '../../../../common'
import { TableCell, TableCheckbox, TableGroup, TableRow } from '../Table.styled'

import { DigestTitle, DigestRow, DigestBtn, DigestDate } from './Digest.styled'
import { DigestSelect } from './DigestSelect'

type DigestProps = {
  userTgId: number | null | undefined
  digest: smApi.Omni.DigestRes | null
  handleOnChangeDigest: (params: changeModuleParams) => void
  handleSendNowDigest: () => void
  handleClickTgCheckboxState: () => void
}

export const Digest = ({
  userTgId,
  digest,
  handleOnChangeDigest,
  handleSendNowDigest,
  handleClickTgCheckboxState,
}: DigestProps) => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  return (
    <>
      {digest && (
        <TableGroup>
          <TableRow>
            <TableCell>
              <DigestTitle>
                Регулярный отчёт
                <CalendarSimple />
              </DigestTitle>
              <DigestRow>
                <DigestSelect
                  digest={digest}
                  handleOnChangeDigest={handleOnChangeDigest}
                />
                <DigestDate>
                  следующий отчет {}
                  {dayjs
                    .utc(digest.nextDeliveryDatetime)
                    .tz(userTimeZone)
                    .format(SHORT_DISPLAY_DATE_FORMAT)}
                </DigestDate>
                <DigestBtn onClick={handleSendNowDigest}>
                  Отправить сейчас
                </DigestBtn>
              </DigestRow>
            </TableCell>
            <TableCell></TableCell>
            <TableCell>
              <TableCheckbox
                checked={digest.allowEmail}
                colors={tableCheckboxColors}
                onChange={() =>
                  handleOnChangeDigest({
                    changedField: 'allowEmail',
                    value: !digest.allowEmail,
                  })
                }
              />
            </TableCell>
            <TableCell>
              <TableCheckbox
                checked={digest.allowTelegram}
                disabled={!userTgId}
                colors={tableCheckboxColors}
                onClick={() => handleClickTgCheckboxState()}
                onChange={() =>
                  handleOnChangeDigest({
                    changedField: 'allowTelegram',
                    value: !digest.allowTelegram,
                  })
                }
              />
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableGroup>
      )}
    </>
  )
}
