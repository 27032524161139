import { Foreign } from '@gmini/ui-kit'
import styled from 'styled-components'

export const SubscriberConfigsTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.01em;
  color: var(--dark-blue-color);
  padding-top: 9px;
  padding-bottom: 19px;
`

export const StyledForeign = styled(Foreign)`
  && {
    min-height: 18px;
    width: 18px;
    height: 18px;
  }
`

export const ModuleName = styled.div`
  font-size: 14px;
  letter-spacing: 0.01em;
  color: var(--dark-blue-color-2);
`

export const ModuleFilterLink = styled.a``

export const SubscriberConfigTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: (--dark-blue-color);
`

export const SubscriberConfigAttrs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 10px;
`

export const SubscriberConfigAttr = styled.div`
  display: flex;
  gap: 4px;
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 61, 0.6);
`

export const SubscriberConfigAttrLabel = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 61, 0.6);
`

export const SubscriberConfigAttrValue = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 61, 0.6);
`

export const SubscriberConfigStatutes = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

type SubscriberConfigStatus = {
  separatorColor: string
}

export const SubscriberConfigStatus = styled.div<SubscriberConfigStatus>`
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 400;
  font-size: 12px;
  line-height: 175%;
  letter-spacing: 0.01em;
  color: var(--dark-blue-color-2);

  &:before {
    content: '';
    border-radius: 4px;
    width: 4px;
    height: 18px;
    background: ${({ separatorColor }) => separatorColor};
  }
`

export const SubscriberConfigRow = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px 8px;
`

export const SubscribeConfigAttrValueIds = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`

export const SubscribeConfigAttrValueId = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(53, 59, 96, 0.1);
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  color: var(--dark-blue-color);
  border-radius: 4px;
  padding: 3.5px 8px;
`
