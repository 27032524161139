import { createStore } from 'effector'

import * as ismApi from '@gmini/ism-api-sdk'
import { useStore } from 'effector-react'

export function createIssuesService() {
  const fetchStatusList = ismApi.IssueStatus.fetchList.createContext()
  const deleteSavedFilter = ismApi.Filters.deleteFilter.createContext()

  const fetchStatusListPending$ = fetchStatusList.pending$

  const statusList$ = createStore<ismApi.IssueStatus.Data[]>([]).on(
    fetchStatusList.doneData,
    (_state, result) => result,
  )

  const statusById$ = createStore<Record<
    ismApi.IssueStatus.Enum,
    ismApi.IssueStatus.Data
  > | null>(null).on(fetchStatusList.doneData, (_state, result) =>
    result.reduce(
      (acc: Record<ismApi.IssueStatus.Enum, ismApi.IssueStatus.Data>, item) => {
        acc[item.status] = item

        return acc
      },
      {} as Record<ismApi.IssueStatus.Enum, ismApi.IssueStatus.Data>,
    ),
  )

  function useStatusList() {
    return useStore(statusList$)
  }

  function useStatusById() {
    return useStore(statusById$)
  }

  const fetchSavedFilters = ismApi.Filters.fetchSavedFilters.createContext()
  const fetchSavedFiltersPending$ = fetchSavedFilters.pending$

  const savedFilterList$ = createStore<ismApi.Filters.IssueFilterType[]>([]).on(
    ismApi.Filters.fetchSavedFilters.doneData,
    (_, result) => result,
  )

  const savedFilterById$ = createStore<
    Record<string, ismApi.Filters.IssueFilterType>
  >({}).on(fetchSavedFilters.doneData, (_state, result) =>
    result.reduce(
      (acc: Record<string, ismApi.Filters.IssueFilterType>, item) => {
        if (item.publicId) {
          acc[item.publicId] = item
        }
        return acc
      },
      {},
    ),
  )

  function useSavedFilterList() {
    return useStore(savedFilterList$)
  }

  function useSavedFilterById() {
    return useStore(savedFilterById$)
  }

  return {
    fetchStatusList,
    fetchStatusListPending$,
    statusById$,
    savedFilterList$,
    savedFilterById$,
    useStatusList,
    useStatusById,
    fetchSavedFilters,
    fetchSavedFiltersPending$,
    deleteSavedFilter,
    useSavedFilterList,
    useSavedFilterById,
  }
}
