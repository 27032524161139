import {
  FormattedAttributes,
  FormattedAttributesParams,
} from '../types/subscribeTypes'

export const formattedSubscriptionAttrs = ({
  subscriptionAttrs,
  statuses,
  users,
  companies,
  roles,
  attributes,
}: FormattedAttributesParams): FormattedAttributes =>
  subscriptionAttrs.reduce<FormattedAttributes>(
    (acc, attr) => {
      if (attr.attribute === 'issue.status') {
        acc.attrStatuses.push({
          ...attr,
          valueLabel: statuses
            ? statuses[attr.value as keyof typeof statuses].name
            : attr.valueLabel,
        })
      } else if (attr.source === 'COMPANY' && attr.attribute === 'approvers') {
        const company = companies[attr.value]
        if (company) {
          acc.attrApprovers.push(company.companyName)
        }
      } else if (attr.source === 'ROLE' && attr.attribute === 'approvers') {
        const role = roles[Number(attr.value)]
        if (role) {
          acc.attrApprovers.push(role.title)
        }
      } else if (attr.source === 'USER' && attr.attribute === 'approvers') {
        const user = users[attr.value]
        if (user) {
          acc.attrApprovers.push(user.name)
        }
      } else if (
        attr.source === 'PAYLOAD' &&
        attr.attribute === 'issue.attributeValueIds'
      ) {
        const attribute = attributes[Number(attr.value)]
        if (attribute) {
          acc.attrValues.push(attribute.name)
        }
      }
      return acc
    },
    { attrStatuses: [], attrApprovers: [], attrValues: [] },
  )
