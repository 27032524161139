import React from 'react'

import { TableCell, TableRow, TableHeadStyled } from './Table.styled'

type HeadProps = {
  dataHead: { id: number; name: string }[]
}

export const TableHead = ({ dataHead }: HeadProps) =>
  dataHead.length > 0 ? (
    <TableHeadStyled>
      <TableRow>
        {dataHead.map(({ id, name }) => (
          <TableCell key={id}>{name}</TableCell>
        ))}
      </TableRow>
    </TableHeadStyled>
  ) : null
