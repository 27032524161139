import { createStore } from 'effector'

import * as smApi from '@gmini/sm-api-sdk'
import { useStore } from 'effector-react'
import { userInfo$ } from '@gmini/common/lib/auth/auth'
import { tokenData$ } from '@gmini/common/lib/keycloakAuth'

import { UsersService } from './types'

export function createUsersService(): UsersService {
  const fetchUserList = smApi.Auth.User.fetchList.createContext()
  const fetchUserListPending$ = fetchUserList.pending$

  const userList$ = createStore<smApi.Auth.UserData[]>([]).on(
    fetchUserList.doneData,
    (_state, result) => result,
  )

  const userById$ = createStore<Record<string, smApi.Auth.UserData>>({}).on(
    fetchUserList.doneData,
    (_state, result) =>
      result.reduce((acc: Record<string, smApi.Auth.UserData>, item) => {
        acc[item.id] = item

        return acc
      }, {}),
  )

  function useUserList() {
    return useStore(userList$)
  }

  function useUserById() {
    return useStore(userById$)
  }

  function useUserInfo() {
    return useStore(userInfo$)
  }

  function useTokenData() {
    return useStore(tokenData$)
  }

  return {
    fetchUserList,
    fetchUserListPending$,
    userList$,
    useUserList,
    userById$,
    useUserById,
    useUserInfo,
    useTokenData,
  }
}
