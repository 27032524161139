import { Header } from '../Header'
import { Modal } from '../Modal'
import { Attributes } from '../Attributes'

import { UnsubscribeWarningInfo } from '../UnsubscribeWarningInfo'

import { FormattedSubscription } from '../../../common'

import { Box, UnsubscribeButton } from './Unsubscribe.styled'

type UnsubscribeProps = {
  formattedSubscription: FormattedSubscription | null
  isOpen: boolean
  handleClose: () => void
  handleClickOpen: () => void
  handleUnsubscribe: () => void
}

export const Unsubscribe = ({
  formattedSubscription,
  handleClose,
  isOpen,
  handleClickOpen,
  handleUnsubscribe,
}: UnsubscribeProps) => (
  <>
    <Box>
      <Header />
      <UnsubscribeWarningInfo filterName={formattedSubscription?.filterName} />

      <Attributes formattedSubscription={formattedSubscription} />
      <UnsubscribeButton onClick={handleClickOpen}>
        Отписаться от уведомления
      </UnsubscribeButton>
    </Box>

    <Modal
      open={isOpen}
      handleClose={handleClose}
      handleUnsubscribe={handleUnsubscribe}
    />
  </>
)
