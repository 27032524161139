import React from 'react'
import * as smApi from '@gmini/sm-api-sdk'

import { Bell } from '@gmini/ui-kit/lib/icons'

import { TableCell, TableRow, TableCheckbox, TableGroup } from '../Table.styled'
import { tableCheckboxColors } from '../TableCheckboxColors'
import {
  CheckboxStates,
  changeRecipientModuleConfigParams,
} from '../../../types/LkTypes'

import {
  RecipientModuleConfigTitle,
  RecipientModuleConfigSiteName,
  RecipientModuleConfigSiteDescription,
} from './RecipientModuleConfigs.styled'

type RecipientModuleConfigsProps = {
  userTgId: number | null | undefined
  recipientModuleConfigs: smApi.Omni.RecipientModuleConfigsRes | null
  recipientModuleConfigsCheckboxStates: CheckboxStates
  handleOnChangeRecipientModuleConfig: (
    params: changeRecipientModuleConfigParams,
  ) => void
  handleOnChangeRecipientModuleConfigs: (
    field: 'allowEmail' | 'allowTelegram',
  ) => void
  handleClickTgCheckboxState: () => void
}

export const RecipientModuleConfigs = ({
  userTgId,
  recipientModuleConfigs,
  recipientModuleConfigsCheckboxStates,
  handleOnChangeRecipientModuleConfig,
  handleOnChangeRecipientModuleConfigs,
  handleClickTgCheckboxState,
}: RecipientModuleConfigsProps) => (
  <>
    {recipientModuleConfigs && (
      <TableGroup>
        <TableRow>
          <TableCell>
            <RecipientModuleConfigTitle>
              Основные
              <Bell />
            </RecipientModuleConfigTitle>
          </TableCell>
          <TableCell></TableCell>
          <TableCell>
            <TableCheckbox
              checked={recipientModuleConfigsCheckboxStates.allEmailsChecked}
              indeterminate={
                recipientModuleConfigsCheckboxStates.emailIndeterminate
              }
              colors={tableCheckboxColors}
              onChange={() =>
                handleOnChangeRecipientModuleConfigs('allowEmail')
              }
            />
          </TableCell>
          <TableCell>
            <TableCheckbox
              checked={recipientModuleConfigsCheckboxStates.allTelegramsChecked}
              indeterminate={
                recipientModuleConfigsCheckboxStates.telegramIndeterminate
              }
              colors={tableCheckboxColors}
              disabled={!userTgId}
              onClick={() => handleClickTgCheckboxState()}
              onChange={() =>
                handleOnChangeRecipientModuleConfigs('allowTelegram')
              }
            />
          </TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
        {recipientModuleConfigs.map(
          ({ id, allowEmail, allowTelegram, module }) => (
            <TableRow key={id}>
              <TableCell>
                <RecipientModuleConfigSiteName>
                  {module.siteName}
                </RecipientModuleConfigSiteName>
                <RecipientModuleConfigSiteDescription>
                  {module.siteDescription}
                </RecipientModuleConfigSiteDescription>
              </TableCell>
              <TableCell></TableCell>
              <TableCell>
                <TableCheckbox
                  checked={allowEmail}
                  colors={tableCheckboxColors}
                  onChange={() =>
                    handleOnChangeRecipientModuleConfig({
                      id,
                      changedField: 'allowEmail',
                      value: !allowEmail,
                    })
                  }
                />
              </TableCell>
              <TableCell>
                <TableCheckbox
                  checked={allowTelegram}
                  colors={tableCheckboxColors}
                  disabled={!userTgId}
                  onClick={() => handleClickTgCheckboxState()}
                  onChange={() =>
                    handleOnChangeRecipientModuleConfig({
                      id,
                      changedField: 'allowTelegram',
                      value: !allowTelegram,
                    })
                  }
                />
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          ),
        )}
      </TableGroup>
    )}
  </>
)
