import React from 'react'
import * as smApi from '@gmini/sm-api-sdk'

import {
  CheckboxStates,
  FormattedSubscribeConfigs,
  UnsubscribeParams,
  changeModuleParams,
  changeRecipientModuleConfigParams,
  changeSubscriberConfigParams,
} from '../../types/LkTypes'

import {
  TableStyled,
  TableBody,
  StyledScrollBar,
  TableContainer,
} from './Table.styled'

import { RecipientModuleConfigs } from './RecipientModuleConfigs/RecipientModuleConfigs'
import { TableHead } from './TableHead'
import { Digest } from './Digest'
import { SubscriberConfigs } from './SubscriberConfigs'

type TableProps = {
  dataHead: { id: number; name: string }[]
  userTgId: number | null | undefined
  digest: smApi.Omni.DigestRes | null
  recipientModuleConfigs: smApi.Omni.RecipientModuleConfigsRes | null
  recipientModuleConfigsCheckboxStates: CheckboxStates
  subscriberConfigs: FormattedSubscribeConfigs | null
  subscriberConfigsCheckboxStates: CheckboxStates
  handleOnChangeRecipientModuleConfig: (
    params: changeRecipientModuleConfigParams,
  ) => void
  handleOnChangeRecipientModuleConfigs: (
    field: 'allowEmail' | 'allowTelegram',
  ) => void
  handleOnChangeDigest: (params: changeModuleParams) => void
  handleSendNowDigest: () => void
  handleOnChangeSubscriberConfig: (params: changeSubscriberConfigParams) => void
  handleOnChangeSubscriberConfigs: (
    field: 'allowEmail' | 'allowTelegram',
  ) => void
  handleOnUnsubscribe: (params: UnsubscribeParams) => void
  handleClickTgCheckboxState: () => void
}

export const Table = ({
  dataHead,
  userTgId,
  digest,
  recipientModuleConfigs,
  recipientModuleConfigsCheckboxStates,
  subscriberConfigsCheckboxStates,
  subscriberConfigs,
  handleOnChangeDigest,
  handleSendNowDigest,
  handleOnChangeRecipientModuleConfig,
  handleOnChangeRecipientModuleConfigs,
  handleOnChangeSubscriberConfig,
  handleOnChangeSubscriberConfigs,
  handleOnUnsubscribe,
  handleClickTgCheckboxState,
}: TableProps) => (
  <TableContainer>
    <StyledScrollBar autoHide={false}>
      <TableStyled>
        <TableHead dataHead={dataHead} />
        <TableBody>
          <Digest
            userTgId={userTgId}
            digest={digest}
            handleOnChangeDigest={handleOnChangeDigest}
            handleSendNowDigest={handleSendNowDigest}
            handleClickTgCheckboxState={handleClickTgCheckboxState}
          />
          <RecipientModuleConfigs
            userTgId={userTgId}
            recipientModuleConfigs={recipientModuleConfigs}
            recipientModuleConfigsCheckboxStates={
              recipientModuleConfigsCheckboxStates
            }
            handleOnChangeRecipientModuleConfig={
              handleOnChangeRecipientModuleConfig
            }
            handleOnChangeRecipientModuleConfigs={
              handleOnChangeRecipientModuleConfigs
            }
            handleClickTgCheckboxState={handleClickTgCheckboxState}
          />
          <SubscriberConfigs
            userTgId={userTgId}
            subscriberConfigs={subscriberConfigs}
            subscriberConfigsCheckboxStates={subscriberConfigsCheckboxStates}
            handleOnChangeSubscriberConfig={handleOnChangeSubscriberConfig}
            handleOnChangeSubscriberConfigs={handleOnChangeSubscriberConfigs}
            handleOnUnsubscribe={handleOnUnsubscribe}
            handleClickTgCheckboxState={handleClickTgCheckboxState}
          />
        </TableBody>
      </TableStyled>
    </StyledScrollBar>
  </TableContainer>
)
