import { Button, ToggleSwitch } from '@gmini/ui-kit'

import { BellSettings, Telegram, WarningFilled } from '@gmini/ui-kit/lib/icons'

import { envLinkTgAuth } from '../../../../config'

import {
  HeadDsc,
  HeadNotificationsBtn,
  HeadNotificationsName,
  HeadRow,
  HeadStyled,
  HeadTelegram,
  HeadTelegramLink,
  HeadTelegramRow,
  HeadTelegramWarning,
  HeadTitle,
} from './Head.styled'

type HeadProps = {
  currentUrl: string
  userTgId: number | null | undefined
  isToggleActive: boolean
  isWarningTgShow: boolean
  handleToggleAllowNotifications: () => void
  handleUnlinkTelegram: () => void
}

export const Head = ({
  currentUrl,
  userTgId,
  isToggleActive,
  isWarningTgShow,
  handleToggleAllowNotifications,
  handleUnlinkTelegram,
}: HeadProps) => (
  <HeadStyled>
    <HeadRow>
      <HeadTitle>
        <BellSettings />
        Настройка уведомлений
      </HeadTitle>
      <HeadNotificationsBtn>
        <HeadNotificationsName>Получать уведомления</HeadNotificationsName>
        <ToggleSwitch
          size='medium'
          color='#4c5ecf'
          active={isToggleActive}
          onToggle={handleToggleAllowNotifications}
        />
      </HeadNotificationsBtn>
    </HeadRow>
    <HeadRow>
      <HeadDsc>
        Для каждого события вы можете включить/выключить определенные типы
        уведомлений.
      </HeadDsc>
      <HeadTelegram>
        <HeadTelegramRow>
          {isWarningTgShow && !userTgId && (
            <HeadTelegramWarning>
              <WarningFilled color='#f5a623' />
              Необходимо настроить уведомления в Telegram
            </HeadTelegramWarning>
          )}
          {!userTgId && (
            <HeadTelegramLink
              href={`${envLinkTgAuth}?redirect_url=${currentUrl}`}
            >
              <Telegram />
              Настроить Telegram
            </HeadTelegramLink>
          )}
        </HeadTelegramRow>
        {userTgId && (
          <Button size='small' onClick={handleUnlinkTelegram}>
            Выйти из Telegram
          </Button>
        )}
      </HeadTelegram>
    </HeadRow>
  </HeadStyled>
)
