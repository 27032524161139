import { useStore } from 'effector-react'

import { isLoading$, unsubscribeService } from '../pages/UnsubscribePage/model'

export const useUnsubscribeData = () => {
  const subscription = unsubscribeService.useSubscription()
  const userIds = unsubscribeService.useUniqueUserIds()
  const attributeIds = unsubscribeService.useUniqueAttributeIds()
  const formattedSubscription = unsubscribeService.useFormattedSubscription()
  const isLoading = useStore(isLoading$)
  const unsubscribePending = useStore(unsubscribeService.unsubscribePending$)

  return {
    subscription,
    formattedSubscription,
    attributeIds,
    userIds,
    isLoading,
    unsubscribePending,
  }
}
