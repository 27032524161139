import { useStore } from 'effector-react'

import { isLoading$, lkService } from '../pages/NotificationsPage/model'

export const useLkData = () => {
  const user = lkService.useUser()
  const userIds = lkService.useUniqueUserIds()
  const attributeIds = lkService.useUniqueAttributeIds()
  const userTgId = user?.telegramId
  const isAllowNotifications = user?.allowNotifications || false
  const digest = lkService.useDigest()
  const recipientModuleConfigs = lkService.useRecipientModuleConfigs()
  const formattedSubscribeConfigs = lkService.useFormattedSubscribeConfigs()
  const isLoading = useStore(isLoading$)

  return {
    isAllowNotifications,
    digest,
    recipientModuleConfigs,
    userIds,
    attributeIds,
    formattedSubscribeConfigs,
    userTgId,
    isLoading,
  }
}
