import { Header, HeaderLogoWrap, Logo } from '@gmini/ui-kit'
import * as smApi from '@gmini/sm-api-sdk'

import { UserInfo } from '@gmini/common/lib/auth/auth'
import { Notifications } from '@gmini/ui-common'

import {
  BrandContainer,
  BrandTitle,
  HeaderWrapper,
  NotificationsWrapper,
} from './LkHeader.styles'

type LkHeaderProps = {
  userInfo: UserInfo | null
  tokenData: {
    accessToken: string
    refreshToken: string
    idToken: string
  } | null
  NavbarDropDown: JSX.Element
  opened: boolean
  onLogout: () => void
  notificationService: smApi.OmniNotificationService
}

export const LkHeader = ({
  userInfo,
  tokenData,
  NavbarDropDown,
  opened,
  onLogout,
  notificationService,
}: LkHeaderProps) => {
  if (!userInfo) {
    return null
  }

  return (
    <HeaderWrapper>
      <Header
        onLogout={() => onLogout()}
        brand={
          <BrandContainer active={opened}>
            <HeaderLogoWrap>
              <Logo />
            </HeaderLogoWrap>
            <BrandTitle>Личный кабинет</BrandTitle>
            {NavbarDropDown}
          </BrandContainer>
        }
        titleSecondary={
          <>
            {notificationService && tokenData?.accessToken && userInfo.id && (
              <NotificationsWrapper>
                <Notifications
                  userToken={tokenData.accessToken}
                  zIndex={'20'}
                  notificationService={notificationService}
                />
              </NotificationsWrapper>
            )}
          </>
        }
        userInfo={userInfo}
      />
    </HeaderWrapper>
  )
}
