import styled from 'styled-components'
import { Checkbox, ScrollBar } from '@gmini/ui-kit'

export const TableContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
`

export const TableStyled = styled.div`
  border-radius: 6px;
  width: calc(100% - 24px);
  border: 1px solid #e5e7f1;
`

export const TableRow = styled.div`
  display: grid;
  grid-template-columns: minmax(200px, 490px) repeat(3, 200px) auto 36px 36px;
  padding: 15px 21px;
  border-radius: 4px;

  &:nth-child(even) {
    background-color: #f4f4f8;
  }
`

export const TableCell = styled.div`
  &:not(:first-child) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const TableHeadStyled = styled.div`
  background-color: #edeff5;
  border-bottom: 1px solid #edeff5;
  border-radius: 6px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 10;

  ${TableCell} {
    white-space: nowrap;
  }

  ${TableCell} {
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.01em;
    color: #a2a3b7;
  }

  ${TableRow} {
    padding: 10px 32px;
  }
`

export const TableBody = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  min-height: 0;
  flex-grow: 1;
  padding: 10px;
`

export const TableGroup = styled.div`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: -10px;
    right: 0;
    width: calc(100% + 20px);
    height: 1px;
    background-color: #e5e7f1;
  }
`

export const StyledScrollBar = styled(ScrollBar)`
  & .simplebar-scrollbar::before {
    background: rgba(0, 10, 87, 0.07);
    width: 10px;
  }

  & .simplebar-track.simplebar-vertical {
    background: #f4f4f8;
    border-radius: 10px;
    right: 7px;
    height: calc(100% - 7px);
  }

  & .simplebar-placeholder {
    width: auto !important;
  }
`
export const TableCheckbox = styled(Checkbox)``

export const TableRadioContainer = styled.div`
  display: flex;
  gap: 12px;
  padding: 2px 0;
`

export const TableRadioBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
`

export const TableRadioText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: var(--dark-blue-color-2);
`
