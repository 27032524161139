import React from 'react'

import UnsubscribeLogoIcon from './UnsubscribeLogoIcon.svg'

import {
  UnsubscribeLogo,
  UnsubscribeText,
} from './UnsubscribeWarningInfo.styled'

type UnsubscribeWarningInfoProps = {
  filterName?: string | null
}

export const UnsubscribeWarningInfo = ({
  filterName,
}: UnsubscribeWarningInfoProps) => (
  <>
    <UnsubscribeLogo src={UnsubscribeLogoIcon} />
    <UnsubscribeText>
      Вы собираетесь отписаться от уведомления по фильтру <b>{filterName}</b> со
      следующими атрибутами:
    </UnsubscribeText>
  </>
)
