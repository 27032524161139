import * as smApi from '@gmini/sm-api-sdk'
import * as ismApi from '@gmini/ism-api-sdk'

import { FormattedSubscribeConfigs } from '../types/LkTypes'
import {
  formattedSubscriptionAttrs,
  formattedSubscriptionFilter,
} from '../../common/helpers'

export const formattedSubscribeConfigs = ({
  subscriptions,
  filters,
  modulesTaxonomy,
  statuses,
  users,
  companies,
  roles,
  attributes,
}: {
  subscriptions: smApi.Omni.SubscriberConfigsRes
  filters: Record<string, ismApi.Filters.IssueFilterType>
  modulesTaxonomy: smApi.Omni.ModulesTaxonomyRes
  statuses: Record<ismApi.IssueStatus.Enum, ismApi.IssueStatus.Data> | null
  users: Record<string, smApi.Auth.UserData>
  companies: Record<string, smApi.HubManagement.Company.Data>
  roles: Record<number, smApi.Auth.Role.Item>
  attributes: Record<number, smApi.Attribute>
}): FormattedSubscribeConfigs =>
  subscriptions.map(subscription => {
    const moduleName = modulesTaxonomy[subscription.moduleId]
    const formattedFilter = formattedSubscriptionFilter({
      subscription,
      filters,
    })
    const subscriptionAttrs = subscription.attributes
    const formattedAttrs = formattedSubscriptionAttrs({
      subscriptionAttrs,
      statuses,
      users,
      companies,
      roles,
      attributes,
    })

    return {
      ...subscription,
      filterId: formattedFilter.filterId,
      filterName: formattedFilter.filterName,
      filterCreatedAt: formattedFilter.filterCreatedAt,
      moduleName,
      attrStatuses: formattedAttrs.attrStatuses,
      attrApprovers: formattedAttrs.attrApprovers,
      attrValues: formattedAttrs.attrValues,
    }
  })
