import {
  FormattedSubscription,
  FormattedSubscriptionParams,
} from '../types/subscribeTypes'

import { formattedSubscriptionAttrs } from './formattedSubscriptionAttrs'
import { formattedSubscriptionFilter } from './formattedSubscriptionFilter'

export const formatSubscription = ({
  attributes,
  companies,
  filters,
  roles,
  statuses,
  subscription,
  users,
}: FormattedSubscriptionParams): FormattedSubscription | null => {
  if (!subscription) {
    return null
  }

  const formattedFilter = formattedSubscriptionFilter({ subscription, filters })
  const formattedAttrs = formattedSubscriptionAttrs({
    subscriptionAttrs: subscription.attributes,
    statuses,
    users,
    companies,
    roles,
    attributes,
  })

  return {
    ...subscription,
    filterId: formattedFilter.filterId,
    filterName: formattedFilter.filterName,
    filterCreatedAt: formattedFilter.filterCreatedAt,
    attrStatuses: formattedAttrs.attrStatuses,
    attrApprovers: formattedAttrs.attrApprovers,
    attrValues: formattedAttrs.attrValues,
  }
}
