import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'

type SidebarStyledProps = {
  isOpen: boolean
}

export const SidebarStyled = styled.div<SidebarStyledProps>`
  display: flex;
  flex-direction: column;
  padding: 8px;
  width: ${({ isOpen }) => (isOpen ? '247px' : '44px')};
  border-right: 1px solid #e5e7f1;
  overflow: hidden;
  transition: width 0.3s ease-in-out;
  flex-shrink: 0;

  ${({ isOpen }) =>
    !isOpen &&
    css`
      ${SidebarBtn} {
        transform: rotate(180deg);
      }

      ${SidebarTitle}, ${Menu} {
        opacity: 0;
        padding: 0;
        width: 0;
        visibility: hidden;
        transition: padding 0.3s linear, width 0.3s linear;
      }
    `}

  ${({ isOpen }) =>
    isOpen &&
    css`
      ${SidebarTitle}, ${Menu} {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.3s linear;
      }
    `}
`

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: flex-start;
  width: 100%;
  padding-right: 4px;
`

export const SidebarBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  background: transparent;
  border: none;
  opacity: 0.5;
  cursor: pointer;
`

export const SidebarTitle = styled.div`
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.01em;
  color: var(--main-color);
  opacity: 0.5;
  display: flex;
  align-items: center;
  height: 46px;
  padding-left: 12px;
`

export const Menu = styled.nav``

type MenuLinkProps = {
  disabled?: boolean
  activeClassName?: string
}

export const MenuLink = styled(NavLink)`
  position: relative;
  display: flex;
  align-items: center;
  height: 46px;
  padding-left: 12px;
  column-gap: 12px;
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: var(--main-color);
  border-radius: 4px;

  &:hover {
    color: #2235aa;

    svg path {
      fill: #2235aa;
    }
  }

  ${(props: MenuLinkProps) =>
    props.activeClassName &&
    css`
      color: #2235aa;
      background-color: #f1f3ff;

      &:after {
        content: '';
        position: absolute;
        right: -8px;
        width: 4px;
        height: 100%;
        background-color: #4c5ecf;
        border-radius: 4px;
      }

      svg path {
        fill: #2235aa;
      }
    `}

  ${(props: MenuLinkProps) =>
    props.disabled &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `}
`
