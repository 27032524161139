import { FetchService } from '@gmini/utils'

import { removeAuthHeader, setAuthHeader } from '@gmini/common/lib/auth/auth'

import { tokenData$ } from '@gmini/common/lib/keycloakAuth'

import * as smApi from '@gmini/sm-api-sdk'

import { refreshTokenFunc } from '@gmini/common/lib/keycloakAuth/auth/authData'

import * as api from '@gmini/ism-api-sdk'

import * as documentManagementApi from '@gmini/sm-api-sdk/lib/DMAPi'

import {
  authorizationServerUrl,
  gStationDocumentManagementServerUrl,
  hubManagementServerUrl,
  issueManagerServerUrl,
  omniServerUrl,
} from '../config'

export const omniNotificationsApi = new FetchService({
  baseUrl: omniServerUrl,
  refreshTokenFunc,
})

export const issueManagerFetch = new FetchService({
  baseUrl: issueManagerServerUrl,
  refreshTokenFunc,
})

export const documentManagement = new FetchService({
  baseUrl: gStationDocumentManagementServerUrl,
})

export const authApi = new FetchService({
  baseUrl: `${authorizationServerUrl}/auth/v1`,
})

export const hubManagement = new FetchService({
  baseUrl: hubManagementServerUrl,
})

export const omniApi = new FetchService({
  baseUrl: omniServerUrl,
  refreshTokenFunc,
})

tokenData$.watch(tokenData => {
  if (tokenData?.accessToken) {
    setAuthHeader(
      [
        omniApi,
        omniNotificationsApi,
        authApi,
        issueManagerFetch,
        documentManagement,
        hubManagement,
      ],
      tokenData.accessToken,
    )
  } else {
    removeAuthHeader([
      omniApi,
      omniNotificationsApi,
      authApi,
      issueManagerFetch,
      documentManagement,
      hubManagement,
    ])
  }
})

smApi.Auth.User.fetch.use(() =>
  authApi.get(`/auth/current-user`).then(smApi.Auth.User.fetch.toPromise),
)

api.issueManagerConnect(issueManagerFetch)
documentManagementApi.DMConnect(documentManagement)
smApi.authConnect(authApi)
smApi.hubManagementConnect(hubManagement)
smApi.omniConnect(omniApi)
smApi.omniNotificationsConnect(omniNotificationsApi)
