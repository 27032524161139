import { useEffect, useMemo, useState } from 'react'

import { useSnackbar } from 'notistack'
import Container from '@mui/material/Container'
import { useKeycloak } from '@react-keycloak/web'

import { useHistory, useLocation } from 'react-router-dom'

import { getModulesLinkFromEnv, useNavbar } from '@gmini/common'

import { useLkData } from '../hooks'

import {
  companiesService,
  issuesService,
  lkService,
  rolesService,
  usersService,
} from '../pages/NotificationsPage/model'
import { DATA_TABLE_HEAD, Loading, SIDEBAR_LINKS } from '../../common'
import {
  PageContentWrapper,
  MainContent,
  Head,
  LkHeader,
  Sidebar,
  PageContentBox,
  Wrapper,
} from '../components'
import { Table } from '../components/Table'
import {
  UnsubscribeParams,
  changeModuleParams,
  changeRecipientModuleConfigParams,
  changeSubscriberConfigParams,
} from '../types/LkTypes'

import { calculateCheckboxStates } from '../helpers'
import { EntitySnackbar } from '../../common/components/EntitySnackbar'
import { unsubscribeService } from '../../Unsubscribe/pages/UnsubscribePage/model'
import { envLinks } from '../../../config'
import { notificationService } from '../../../api/omniNotificationsService'

export const LkContainer = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const {
    isAllowNotifications,
    digest,
    isLoading,
    recipientModuleConfigs,
    userIds,
    attributeIds,
    formattedSubscribeConfigs,
    userTgId,
  } = useLkData()

  const recipientModuleConfigsCheckboxStates = useMemo(
    () => calculateCheckboxStates(recipientModuleConfigs || []),
    [recipientModuleConfigs],
  )

  const subscriberConfigsCheckboxStates = useMemo(
    () => calculateCheckboxStates(formattedSubscribeConfigs || []),
    [formattedSubscribeConfigs],
  )

  const { keycloak } = useKeycloak()
  const userInfo = usersService.useUserInfo()
  const tokenData = usersService.useTokenData()
  const { NavbarDropDown, opened } = useNavbar({
    navModules: getModulesLinkFromEnv(envLinks),
  })
  const location = useLocation()
  const history = useHistory()

  const currentUrl = location.pathname
  const currentFullUrl = `${window.location.origin}${location.pathname}`

  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(true)
  const [isWarningTgShow, setIsWarningTgShow] = useState<boolean>(false)

  const handleToggleSidebar = () => setIsSidebarOpen(!isSidebarOpen)
  const handleClickTgCheckboxState = () => !userTgId && setIsWarningTgShow(true)

  const handleToggleAllowNotifications = () => {
    lkService.updateUser({ allowNotifications: !isAllowNotifications })
  }

  const handleOnChangeDigest = async ({
    changedField,
    value,
  }: changeModuleParams) => {
    const updateData = { [changedField]: value }

    lkService.updateDigestLocal(updateData)

    try {
      await lkService.updateDigest(updateData)
    } catch (error) {
      console.error('Произошла ошибка при обновлении регулярного отчета', error)
    }
  }

  const handleSendNowDigest = async () => {
    try {
      await lkService.sendNowDigest(undefined)
      await lkService.fetchDigest(undefined)

      enqueueSnackbar('', {
        content: function EntitySnackbarContent(key) {
          return (
            <EntitySnackbar
              id={key}
              message={`Регулярный отчёт отправлен`}
              onClose={() => closeSnackbar()}
            />
          )
        },
      })
    } catch (error) {
      console.error('Произошла ошибка при отправке дайджеста', error)
      enqueueSnackbar('Произошла ошибка при отправке', {
        variant: 'error',
      })
    }
  }

  const handleOnChangeRecipientModuleConfig = async ({
    id,
    changedField,
    value,
  }: changeRecipientModuleConfigParams) => {
    const updateData = { id, [String(changedField)]: value }

    lkService.updateRecipientModuleConfigLocal(updateData)

    try {
      await lkService.updateRecipientModuleConfig(updateData)
    } catch (error) {
      console.error('Произошла ошибка при обновлении конфигурации', error)
    }
  }

  const handleOnChangeSubscriberConfig = async ({
    id,
    changedField,
    value,
  }: changeSubscriberConfigParams) => {
    const updateData = { id: Number(id), [String(changedField)]: value }

    lkService.updateSubscriberConfigLocal(updateData)

    try {
      await lkService.updateSubscriberConfig(updateData)
    } catch (error) {
      console.error('Произошла ошибка при обновлении конфигурации', error)
    }
  }

  const handleOnChangeRecipientModuleConfigs = async (
    field: 'allowEmail' | 'allowTelegram',
  ) => {
    const emailConditions =
      recipientModuleConfigsCheckboxStates.emailIndeterminate ||
      !recipientModuleConfigsCheckboxStates.allEmailsChecked

    const telegramConditions =
      recipientModuleConfigsCheckboxStates.telegramIndeterminate ||
      !recipientModuleConfigsCheckboxStates.allTelegramsChecked

    const isCheckAll =
      field === 'allowEmail' ? emailConditions : telegramConditions

    lkService.updateAllRecipientModuleConfigsLocal({
      field,
      value: isCheckAll,
    })

    try {
      await lkService.bulkUpdateRecipientModuleConfigs({
        [field]: isCheckAll,
      })
    } catch (error) {
      console.error('Произошла ошибка при обновлении конфигураций', error)
    }
  }

  const handleOnChangeSubscriberConfigs = async (
    field: 'allowEmail' | 'allowTelegram',
  ) => {
    const emailConditions =
      subscriberConfigsCheckboxStates.emailIndeterminate ||
      !subscriberConfigsCheckboxStates.allEmailsChecked

    const telegramConditions =
      subscriberConfigsCheckboxStates.telegramIndeterminate ||
      !subscriberConfigsCheckboxStates.allTelegramsChecked

    const isCheckAll =
      field === 'allowEmail' ? emailConditions : telegramConditions

    lkService.updateAllSubscribeConfigsLocal({
      field,
      value: isCheckAll,
    })

    try {
      await lkService.bulkUpdateSubscriberConfigs({
        [field]: isCheckAll,
      })
    } catch (error) {
      console.error('Произошла ошибка при обновлении фильтра', error)
    }
  }

  const handleUnlinkTelegram = async () => {
    try {
      await lkService.unlinkUserTg(undefined)
      await lkService.fetchUser(undefined)
    } catch (error) {
      console.error('Произошла ошибка при выходе из лк', error)
    }
  }

  const handleOnUnsubscribe = async ({ publicId, id }: UnsubscribeParams) => {
    try {
      lkService.deleteSavedFilterLocal({ publicId })

      if (publicId) {
        await unsubscribeService.unsubscribe({ id: publicId })
      }

      if (id) {
        await issuesService.deleteSavedFilter({ id })
      }
    } catch (error) {
      console.error('Произошла ошибка при отписке и удалении фильтра', error)
    }
  }

  useEffect(() => {
    issuesService.fetchStatusList.submit()
    issuesService.fetchSavedFilters.submit()
    rolesService.fetchRoleList.submit()
    companiesService.fetchCompanyList.submit()
    lkService.fetchSubscriberConfigs.submit()
    lkService.fetchModulesTaxonomy.submit()
    lkService.fetchUser.submit()
    lkService.fetchDigest.submit()
    lkService.fetchRecipientModuleConfigs.submit()

    const params = new URLSearchParams(location.search)
    const telegramParams = {
      id: Number(params.get('id')),
      firstName: params.get('first_name') || '',
      lastName: params.get('last_name') || '',
      username: params.get('username') || '',
      photoUrl: params.get('photo_url') || undefined,
      authDate: Number(params.get('auth_date')),
      hash: params.get('hash') || '',
    }

    const hasValidParams = Object.entries(telegramParams).some(
      ([key, value]) => {
        if (key === 'photoUrl') {
          return false
        }
        return value !== '' && value !== 0
      },
    )

    const fetchAndHandleTelegramLink = async () => {
      try {
        await lkService.linkUserTg(telegramParams)
        await lkService.fetchUser(undefined)
      } catch (error) {
        console.error('Произошла ошибка при связывании с Telegram', error)
      }

      history.push(location.pathname)
    }

    if (hasValidParams && !userTgId) {
      fetchAndHandleTelegramLink()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (userIds.length > 0) {
      usersService.fetchUserList({ ids: userIds })
    }
  }, [userIds])

  useEffect(() => {
    if (attributeIds.length > 0) {
      lkService.fetchAttributeValues({ valueIds: attributeIds })
    }
  }, [attributeIds])

  if (isLoading) {
    return (
      <Container maxWidth='lg'>
        <Loading />
      </Container>
    )
  }

  return (
    <Wrapper>
      <LkHeader
        userInfo={userInfo}
        tokenData={tokenData}
        NavbarDropDown={NavbarDropDown}
        opened={opened}
        onLogout={keycloak.logout}
        notificationService={notificationService}
      />
      <PageContentWrapper>
        <PageContentBox>
          <Sidebar
            links={SIDEBAR_LINKS}
            currentUrl={currentUrl}
            isOpen={isSidebarOpen}
            handleToggleSidebar={handleToggleSidebar}
          />
          <MainContent>
            <Head
              currentUrl={currentFullUrl}
              userTgId={userTgId}
              isWarningTgShow={isWarningTgShow}
              isToggleActive={isAllowNotifications}
              handleToggleAllowNotifications={handleToggleAllowNotifications}
              handleUnlinkTelegram={handleUnlinkTelegram}
            />
            <Table
              dataHead={DATA_TABLE_HEAD}
              userTgId={userTgId}
              digest={digest}
              recipientModuleConfigs={recipientModuleConfigs}
              recipientModuleConfigsCheckboxStates={
                recipientModuleConfigsCheckboxStates
              }
              subscriberConfigs={formattedSubscribeConfigs}
              subscriberConfigsCheckboxStates={subscriberConfigsCheckboxStates}
              handleOnChangeDigest={handleOnChangeDigest}
              handleSendNowDigest={handleSendNowDigest}
              handleOnChangeRecipientModuleConfig={
                handleOnChangeRecipientModuleConfig
              }
              handleOnChangeRecipientModuleConfigs={
                handleOnChangeRecipientModuleConfigs
              }
              handleOnChangeSubscriberConfig={handleOnChangeSubscriberConfig}
              handleOnChangeSubscriberConfigs={handleOnChangeSubscriberConfigs}
              handleOnUnsubscribe={handleOnUnsubscribe}
              handleClickTgCheckboxState={handleClickTgCheckboxState}
            />
          </MainContent>
        </PageContentBox>
      </PageContentWrapper>
    </Wrapper>
  )
}
