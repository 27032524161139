import { Profile, BellSettings } from '@gmini/ui-kit/lib/icons'

export const statusColors: { [key: string]: string } = {
  DRAFT: 'rgb(229, 231, 241)',
  NOT_RELEVANT: 'rgb(229, 231, 241)',
  NOT_STARTED: 'rgb(162, 163, 183)',
  IN_PROCESS: 'rgb(245, 166, 35)',
  ON_REVIEW: 'rgb(76, 94, 207)',
  CLOSED: 'rgb(11, 187, 123)',
}

export const SHORT_DISPLAY_DATE_FORMAT = 'D MMM в HH:mm'

export const FREQUENCY_TYPE_TEXT = {
  daily: 'Ежедневно',
  weekly: 'Еженедельно',
}

export const DAYS_OF_WEEK_FULL: { [key: string]: string } = {
  monday: 'понедельник',
  tuesday: 'вторник',
  wednesday: 'среда',
  thursday: 'четверг',
  friday: 'пятница',
  saturday: 'суббота',
  sunday: 'воскресенье',
}

export const DAYS_OF_WEEK_SHORT: { [key: string]: string } = {
  monday: 'пн',
  tuesday: 'вт',
  wednesday: 'ср',
  thursday: 'чт',
  friday: 'пт',
  saturday: 'сб',
  sunday: 'вс',
}

export const DAYS_OF_WEEK = [
  { key: 'monday', name: 'Понедельник' },
  { key: 'tuesday', name: 'Вторник' },
  { key: 'wednesday', name: 'Среда' },
  { key: 'thursday', name: 'Четверг' },
  { key: 'friday', name: 'Пятница' },
  { key: 'saturday', name: 'Суббота' },
  { key: 'sunday', name: 'Воскресенье' },
]

export const SIDEBAR_LINKS = [
  {
    name: 'Профиль',
    path: '/',
    disabled: true,
    icon: Profile,
  },
  {
    name: 'Уведомления',
    path: '/lk/notifications/',
    icon: BellSettings,
  },
]

export const DATA_TABLE_HEAD = [
  { id: 1, name: 'События' },
  { id: 2, name: 'Источник' },
  { id: 3, name: 'E-mail' },
  { id: 4, name: 'В Telegram' },
  { id: 5, name: '' },
  { id: 6, name: '' },
  { id: 7, name: '' },
]
