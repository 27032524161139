import { combine, createStore } from 'effector'
import * as smApi from '@gmini/sm-api-sdk'

import { useStore } from 'effector-react'

import {
  CompaniesService,
  FormattedSubscription,
  IssuesService,
  RolesService,
  UsersService,
} from '../../common'
import { formatSubscription } from '../../common/helpers'

export const createUnsubscribeService = (
  usersService: UsersService,
  rolesService: RolesService,
  companiesService: CompaniesService,
  issuesService: IssuesService,
) => {
  const fetchSubscriptionById = smApi.Omni.subscriptionById.createContext()
  const fetchSubscriptionByIdPending$ = fetchSubscriptionById.pending$
  const fetchAttributeValues = smApi.Attribute.fetchAttributeValues.createContext()
  const fetchAttributeValuesPending$ = fetchAttributeValues.pending$

  const unsubscribe = smApi.Omni.unsubscribe.createContext()
  const unsubscribePending$ = unsubscribe.pending$

  const subscription$ = createStore<smApi.Omni.FilterType | null>(null).on(
    fetchSubscriptionById.doneData,
    (_, result) => result,
  )

  const uniqueUserIds$ = createStore<string[]>([]).on(
    subscription$,
    (_, subscription) => {
      if (subscription) {
        const { attributes } = subscription

        const authorIds = attributes
          .filter(attr => attr.attribute === 'issue.ownerId')
          .map(attr => attr.value)

        const userIds = attributes
          .filter(
            attr =>
              attr.attribute === 'issue.assignees' && attr.source === 'USER',
          )
          .map(attr => attr.value)

        const uniqueUserIds = new Set([...authorIds, ...userIds])

        return Array.from(uniqueUserIds).map(String)
      }

      return []
    },
  )
  const uniqueAttributeIds$ = createStore<number[]>([]).on(
    subscription$,
    (_, subscription) => {
      const uniqueAttributeIds = subscription?.attributes.reduce(
        (acc: number[], attr) => {
          if (
            attr.attribute === 'issue.attributeValueIds' &&
            !acc.includes(Number(attr.value))
          ) {
            acc.push(Number(attr.value))
          }
          return acc
        },
        [],
      )

      return uniqueAttributeIds
    },
  )

  const attributeById$ = createStore<Record<number, smApi.Attribute>>({}).on(
    fetchAttributeValues.doneData,
    (_state, result) =>
      result.attributeValues.reduce(
        (acc: Record<string, smApi.Attribute>, item) => {
          acc[item.id] = item

          return acc
        },
        {},
      ),
  )

  const formattedSubscription$ = createStore<FormattedSubscription | null>(
    null,
  ).on(
    combine({
      subscription: subscription$,
      filters: issuesService.savedFilterById$,
      statuses: issuesService.statusById$,
      users: usersService.userById$,
      companies: companiesService.companyById$,
      roles: rolesService.roleById$,
      attributes: attributeById$,
    }),
    (_state, result) => formatSubscription(result),
  )

  function useSubscription() {
    return useStore(subscription$)
  }

  function useUniqueUserIds() {
    return useStore(uniqueUserIds$)
  }

  function useUniqueAttributeIds() {
    return useStore(uniqueAttributeIds$)
  }

  function useFormattedSubscription() {
    return useStore(formattedSubscription$)
  }

  return {
    uniqueUserIds$,
    fetchAttributeValues,
    fetchAttributeValuesPending$,
    fetchSubscriptionById,
    fetchSubscriptionByIdPending$,
    unsubscribe,
    unsubscribePending$,
    useSubscription,
    useUniqueUserIds,
    useUniqueAttributeIds,
    useFormattedSubscription,
  }
}
