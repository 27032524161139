import styled, { css } from 'styled-components'

export const Digest = styled.div``
export const DigestTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.01em;
  color: var(--dark-blue-color);
`

export const DigestRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  align-items: center;
`
export const DigestSelectContainer = styled.div`
  position: relative;
  margin-right: 12px;
`

type DigestSelectModalHeadProps = {
  frequencyType: 'daily' | 'weekly'
}

export const DigestSelectModalHead = styled.div<DigestSelectModalHeadProps>`
  position: relative;
  display: flex;
  flex-direction: column;

  ${({ frequencyType }) =>
    frequencyType === 'weekly' &&
    css`
      padding-bottom: 8px;

      &:after {
        content: '';
        position: absolute;
        left: -16px;
        bottom: 4px;
        width: calc(100% + 32px);
        height: 1px;
        background-color: #e5e7f1;
      }
    `}
`

export const DigestSelectModalDays = styled.div`
  display: flex;
  flex-direction: column;
`

type DigestSelectTypeBtnProps = {
  isModalOpen: boolean
}

export const DigestSelectTypeBtn = styled.button<DigestSelectTypeBtnProps>`
  display: flex;
  align-items: center;
  padding: 0;
  padding-right: 8px;
  background-color: transparent;
  column-gap: 4px;
  border: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 171%;
  color: var(--main-color);
  cursor: pointer;

  &:after {
    content: '';
    margin-top: 5px;
    border: 5px solid transparent;
    border-top: 5px solid var(--main-color);
  }

  ${({ isModalOpen }) =>
    isModalOpen &&
    css`
      &:after {
        margin-top: -5px;
        transform: scale(1, -1);
      }
    `}
`

type DigestSelectModalProps = {
  isModalOpen: boolean
}

export const DigestSelectModal = styled.div<DigestSelectModalProps>`
  position: absolute;
  z-index: 9;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
  background: #fff;
  padding: 7px 16px;
  border-radius: 4px;
  max-width: 170px;
  display: ${({ isModalOpen }) => (isModalOpen ? 'block' : 'none')};
`

export const DigestSelectModalList = styled.div``

export const DigestDate = styled.div`
  font-size: 14px;
  line-height: 143%;
  color: var(--main-color);
  opacity: 0.7;
  margin-right: 15px;
`

export const DigestBtn = styled.button`
  background: transparent;
  border: none;
  font-size: 14px;
  line-height: 143%;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  color: var(--blue-color);
  padding: 0;
  white-space: nowrap;
  cursor: pointer;
`

export const DigestDayContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 2px 0;
`

export const DigestNameDay = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: var(--dark-blue-color-2);
`
