import React from 'react'

import { Collapse } from '@gmini/ui-kit/lib/icons'

import {
  Head,
  SidebarStyled,
  Menu,
  MenuLink,
  SidebarBtn,
  SidebarTitle,
} from './Sidebar.styled'

type SidebarLink = {
  name: string
  path: string
  icon?: React.ElementType
  disabled?: boolean
}

type SidebarProps = {
  currentUrl: string
  links: SidebarLink[]
  isOpen: boolean
  handleToggleSidebar: () => void
}

export const Sidebar = ({
  currentUrl,
  links,
  isOpen,
  handleToggleSidebar,
}: SidebarProps) => (
  <SidebarStyled isOpen={isOpen}>
    <Head>
      <SidebarTitle>Основное</SidebarTitle>
      <SidebarBtn onClick={handleToggleSidebar}>
        <Collapse />
      </SidebarBtn>
    </Head>
    <Menu>
      {links.map(({ name, path, icon: Icon, disabled }, index) => (
        <MenuLink
          key={path}
          to={path}
          disabled={disabled}
          activeClassName={currentUrl === path ? 'active' : ''}
        >
          {Icon && <Icon />}
          {name}
        </MenuLink>
      ))}
    </Menu>
  </SidebarStyled>
)
