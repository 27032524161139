import { createStore } from 'effector'

import * as smApi from '@gmini/sm-api-sdk'
import { useStore } from 'effector-react'

import { RolesService } from './types'

export function createRolesService(): RolesService {
  const fetchRoleList = smApi.Auth.Role.fetchList.createContext()
  const fetchRoleListPending$ = fetchRoleList.pending$

  const roleList$ = createStore<smApi.Auth.Role.Item[]>([]).on(
    fetchRoleList.doneData,
    (_state, result) => result,
  )

  const roleById$ = createStore<Record<number, smApi.Auth.Role.Item>>({}).on(
    fetchRoleList.doneData,
    (_state, result) =>
      result.reduce((acc: Record<number, smApi.Auth.Role.Item>, item) => {
        acc[item.id] = item

        return acc
      }, {}),
  )

  function useRoleList() {
    return useStore(roleList$)
  }

  function useRoleById() {
    return useStore(roleById$)
  }

  return {
    fetchRoleList,
    fetchRoleListPending$,
    roleList$,
    useRoleList,
    roleById$,
    useRoleById,
  }
}
