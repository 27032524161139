import { LkContainer } from '../../containers'
import { WithPageTitle } from '../../../common'

import './model'

export const NotificationsPage = () => (
  <WithPageTitle title='Уведомления | Личный кабинет'>
    <LkContainer />
  </WithPageTitle>
)
