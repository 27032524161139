import { FormattedSubscriptionFilterParams } from '../types'

export const formattedSubscriptionFilter = ({
  subscription,
  filters,
}: FormattedSubscriptionFilterParams) => {
  const filter = filters[subscription.publicId]
  const filterId = filter && filter.id
  const filterName = filter && filter.name
  const filterCreatedAt =
    subscription.attributes.find(
      attr => attr.attribute === 'issue.createdAt',
    ) || null

  return {
    filterId,
    filterName,
    filterCreatedAt,
  }
}
