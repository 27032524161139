import React from 'react'

import { IconButton, Tooltip, Trash } from '@gmini/ui-kit'

import { EyeFilter } from '@gmini/ui-kit/lib/icons'

import { TableCell, TableRow, TableCheckbox, TableGroup } from '../Table.styled'
import { tableCheckboxColors } from '../TableCheckboxColors'
import {
  CheckboxStates,
  FormattedSubscribeConfigs,
  UnsubscribeParams,
  changeSubscriberConfigParams,
} from '../../../types/LkTypes'

import {
  SPA_LINKS_MODULE_ID_CONFIG,
  envModuleLinks,
} from '../../../../../config'

import { statusColors } from '../../../../common'

import {
  ModuleFilterLink,
  ModuleName,
  StyledForeign,
  SubscribeConfigAttrValueId,
  SubscribeConfigAttrValueIds,
  SubscriberConfigAttr,
  SubscriberConfigAttrLabel,
  SubscriberConfigAttrValue,
  SubscriberConfigAttrs,
  SubscriberConfigRow,
  SubscriberConfigStatus,
  SubscriberConfigStatutes,
  SubscriberConfigTitle,
  SubscriberConfigsTitle,
} from './SubscriberConfigs.styled'

type SubscriberConfigsProps = {
  userTgId: number | null | undefined
  subscriberConfigs: FormattedSubscribeConfigs | null
  subscriberConfigsCheckboxStates: CheckboxStates
  handleOnChangeSubscriberConfigs: (
    field: 'allowEmail' | 'allowTelegram',
  ) => void
  handleOnChangeSubscriberConfig: (params: changeSubscriberConfigParams) => void
  handleOnUnsubscribe: (params: UnsubscribeParams) => void
  handleClickTgCheckboxState: () => void
}

export const SubscriberConfigs = ({
  userTgId,
  subscriberConfigs,
  subscriberConfigsCheckboxStates,
  handleOnChangeSubscriberConfig,
  handleOnChangeSubscriberConfigs,
  handleOnUnsubscribe,
  handleClickTgCheckboxState,
}: SubscriberConfigsProps) => (
  <>
    {subscriberConfigs && subscriberConfigs.length > 0 && (
      <TableGroup>
        <TableRow>
          <TableCell>
            <SubscriberConfigsTitle>
              Мои фильтры
              <EyeFilter />
            </SubscriberConfigsTitle>
          </TableCell>
          <TableCell></TableCell>
          <TableCell>
            <TableCheckbox
              checked={subscriberConfigsCheckboxStates.allEmailsChecked}
              indeterminate={subscriberConfigsCheckboxStates.emailIndeterminate}
              colors={tableCheckboxColors}
              onChange={() => handleOnChangeSubscriberConfigs('allowEmail')}
            />
          </TableCell>
          <TableCell>
            <TableCheckbox
              checked={subscriberConfigsCheckboxStates.allTelegramsChecked}
              indeterminate={
                subscriberConfigsCheckboxStates.telegramIndeterminate
              }
              colors={tableCheckboxColors}
              disabled={!userTgId}
              onClick={() => handleClickTgCheckboxState()}
              onChange={() => handleOnChangeSubscriberConfigs('allowTelegram')}
            />
          </TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
        {subscriberConfigs.map(
          ({
            id,
            publicId,
            filterId,
            filterName,
            filterCreatedAt,
            allowEmail,
            allowTelegram,
            moduleId,
            projectUrn,
            moduleName,
            attrStatuses,
            attrApprovers,
            attrValues,
          }) => (
            <TableRow key={id}>
              <TableCell>
                <SubscriberConfigTitle>{filterName}</SubscriberConfigTitle>
                <SubscriberConfigAttrs>
                  <SubscriberConfigRow>
                    {attrStatuses && attrStatuses.length > 0 && (
                      <SubscriberConfigStatutes>
                        {attrStatuses.map(status => (
                          <SubscriberConfigStatus
                            key={`${id}-${status.value}`}
                            separatorColor={statusColors[status.value]}
                          >
                            {status.valueLabel}
                          </SubscriberConfigStatus>
                        ))}
                      </SubscriberConfigStatutes>
                    )}

                    {attrValues && attrValues.length > 0 && (
                      <SubscribeConfigAttrValueIds>
                        {attrValues.map(attr => (
                          <SubscribeConfigAttrValueId key={attr}>
                            {attr}
                          </SubscribeConfigAttrValueId>
                        ))}
                      </SubscribeConfigAttrValueIds>
                    )}
                  </SubscriberConfigRow>

                  {filterCreatedAt && (
                    <SubscriberConfigAttr>
                      <SubscriberConfigAttrLabel>
                        Дата создания:
                      </SubscriberConfigAttrLabel>
                      <SubscriberConfigAttrValue>
                        {filterCreatedAt?.valueLabel}
                      </SubscriberConfigAttrValue>
                    </SubscriberConfigAttr>
                  )}

                  {attrApprovers && attrApprovers.length > 0 && (
                    <SubscriberConfigAttr>
                      <SubscriberConfigAttrLabel>
                        Назначено на:
                      </SubscriberConfigAttrLabel>
                      <SubscriberConfigAttrValue>
                        {attrApprovers.join(', ')}
                      </SubscriberConfigAttrValue>
                    </SubscriberConfigAttr>
                  )}
                </SubscriberConfigAttrs>
              </TableCell>
              <TableCell>
                <ModuleName>{moduleName}</ModuleName>
              </TableCell>
              <TableCell>
                <TableCheckbox
                  checked={allowEmail}
                  colors={tableCheckboxColors}
                  onChange={() =>
                    handleOnChangeSubscriberConfig({
                      id,
                      changedField: 'allowEmail',
                      value: !allowEmail,
                    })
                  }
                />
              </TableCell>
              <TableCell>
                <TableCheckbox
                  checked={allowTelegram}
                  colors={tableCheckboxColors}
                  disabled={!userTgId}
                  onClick={() => handleClickTgCheckboxState()}
                  onChange={() =>
                    handleOnChangeSubscriberConfig({
                      id,
                      changedField: 'allowTelegram',
                      value: !allowTelegram,
                    })
                  }
                />
              </TableCell>
              <TableCell></TableCell>
              <TableCell>
                <Tooltip placement='top' title={'Открыть в модуле'}>
                  <ModuleFilterLink
                    href={`https://${
                      envModuleLinks[
                        moduleId as keyof SPA_LINKS_MODULE_ID_CONFIG
                      ]
                    }?projectUrn=${projectUrn}&issueFilterId=${filterId}`}
                    target='_blank'
                  >
                    <IconButton>{<StyledForeign />}</IconButton>
                  </ModuleFilterLink>
                </Tooltip>
              </TableCell>
              <TableCell>
                <Tooltip placement='top' title={'Удалить'}>
                  <IconButton
                    onClick={() =>
                      handleOnUnsubscribe({ publicId, id: filterId })
                    }
                  >
                    {<Trash />}
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ),
        )}
      </TableGroup>
    )}
  </>
)
