import styled, { css } from 'styled-components'

export const HeaderWrapper = styled.div`
  flex-grow: 0;
`

type BrandContainerProps = {
  active?: boolean
}

export const BrandContainer = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  margin-right: 6px;
  background: inherit;
  color: #00003d;
  font-size: 12px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.1px;
  font-weight: 700;
  line-height: 21px;
  text-transform: uppercase;
  text-decoration: none;
  cursor: default;
  transition: 0.2s ease-out background-color;
  padding: 8px 18px;
  border-radius: 4px;
  position: relative;

  ${(props: BrandContainerProps) =>
    props.active &&
    css`
      background: #e5e7f1;
    `}
`

export const BrandTitle = styled.div`
  margin-right: 50px;
  font-weight: 700;
  font-size: 12px;
  line-height: 175%;
  letter-spacing: 0.01em;
  color: #00003d;
`
export const NotificationsWrapper = styled.div`
  margin-left: auto;
`
