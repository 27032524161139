import React, { useEffect, useMemo, useRef, useState } from 'react'
import * as smApi from '@gmini/sm-api-sdk'

import { RadioButton } from '@gmini/ui-kit'

import { changeModuleParams } from '../../../types/LkTypes'

import {
  TableCheckbox,
  TableRadioBox,
  TableRadioContainer,
  TableRadioText,
} from '../Table.styled'
import { formatActiveDays } from '../../../helpers/formatActiveDays'
import { DAYS_OF_WEEK } from '../../../../common'
import { tableCheckboxColors } from '../TableCheckboxColors'
import { getDigestDayValue } from '../../../helpers/getDigestDayValue'

import {
  DigestSelectContainer,
  DigestSelectModal,
  DigestSelectModalHead,
  DigestSelectModalList,
  DigestDayContainer,
  DigestNameDay,
  DigestSelectModalDays,
  DigestSelectTypeBtn,
} from './Digest.styled'

type DigestSelectProps = {
  digest: smApi.Omni.DigestRes | null
  handleOnChangeDigest: (params: changeModuleParams) => void
}

export const DigestSelect = ({
  digest,
  handleOnChangeDigest,
}: DigestSelectProps) => {
  const formattedDigestText = useMemo(
    () =>
      digest && digest.frequencyType === 'weekly'
        ? `Каждый ${formatActiveDays(digest)}`
        : 'Ежедневно',
    [digest],
  )
  const [isModalOpen, setIsModalOpen] = useState(false)
  const modalRef = useRef<HTMLDivElement>(null)
  const toggleModal = () => setIsModalOpen(!isModalOpen)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setIsModalOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <>
      {digest && (
        <DigestSelectContainer>
          <DigestSelectTypeBtn isModalOpen={isModalOpen} onClick={toggleModal}>
            {formattedDigestText}
          </DigestSelectTypeBtn>
          <DigestSelectModal isModalOpen={isModalOpen} ref={modalRef}>
            <DigestSelectModalHead frequencyType={digest.frequencyType}>
              <TableRadioContainer>
                <TableRadioBox>
                  <RadioButton
                    name='radio'
                    color='#4c5ecf'
                    selected={digest.frequencyType === 'daily'}
                    onChange={() =>
                      handleOnChangeDigest({
                        changedField: 'frequencyType',
                        value: 'daily',
                      })
                    }
                  />
                </TableRadioBox>
                <TableRadioText>Ежедневно</TableRadioText>
              </TableRadioContainer>
              <TableRadioContainer>
                <TableRadioBox>
                  <RadioButton
                    name='radio'
                    color='#4c5ecf'
                    selected={digest.frequencyType === 'weekly'}
                    onChange={() =>
                      handleOnChangeDigest({
                        changedField: 'frequencyType',
                        value: 'weekly',
                      })
                    }
                  />
                </TableRadioBox>
                <TableRadioText>Еженедельно</TableRadioText>
              </TableRadioContainer>
            </DigestSelectModalHead>
            {digest.frequencyType === 'weekly' && (
              <DigestSelectModalDays>
                <DigestSelectModalList>
                  {DAYS_OF_WEEK.map(({ key, name }) => (
                    <DigestDayContainer key={key}>
                      <TableCheckbox
                        checked={getDigestDayValue(digest, key)}
                        colors={tableCheckboxColors}
                        onChange={() =>
                          handleOnChangeDigest({
                            changedField: key,
                            value: !getDigestDayValue(digest, key),
                          })
                        }
                      />
                      <DigestNameDay>{name}</DigestNameDay>
                    </DigestDayContainer>
                  ))}
                </DigestSelectModalList>
              </DigestSelectModalDays>
            )}
          </DigestSelectModal>
        </DigestSelectContainer>
      )}
    </>
  )
}
