import { createPendingCombine } from '../../../../utils'
import {
  createCompaniesService,
  createIssuesService,
  createRolesService,
  createUsersService,
} from '../../../common'
import { createLkService } from '../../services'

export const usersService = createUsersService()
export const rolesService = createRolesService()
export const companiesService = createCompaniesService()
export const issuesService = createIssuesService()
export const lkService = createLkService(
  usersService,
  rolesService,
  companiesService,
  issuesService,
)

export const isLoading$ = createPendingCombine([
  issuesService.fetchStatusListPending$,
  rolesService.fetchRoleListPending$,
  companiesService.fetchCompanyListPending$,
  lkService.fetchSubscriberConfigsPending$,
  lkService.fetchModulesTaxonomyPending$,
  lkService.fetchRecipientModuleConfigsPending$,
])
