import Keycloak from 'keycloak-js'

export const wsUrl = getEnv('SPA_WS_URL')
export const wsUrlV2 = getEnv('SPA_WS_URL_V2')
export const envType = getEnv('SPA_ENV_TYPE')
export const keycloakUrl = getEnv('SPA_KEYCLOAK_SERVER_URL')
export const sentryUrl = getEnv('SPA_FIELD_INSPECTION_SENTRY_DSN')
export const clientId = getEnv('SPA_KEYCLOAK_CLIENT_ID')
export const realm = getEnv('SPA_KEYCLOAK_REALM')
export const amplitudeApiKey = getEnv('SPA_AMPLITUDE_API_KEY')
export const gStationDocumentManagementServerUrl = getEnv(
  'SPA_G_STATION_DOCUMENT_MANAGEMENT_SERVER_URL',
)
export const omniServerUrl = getEnv('SPA_OMNI_SERVER_URL')
export const issueManagerServerUrl = getEnv('SPA_ISSUE_MANAGER_SERVER_URL')
export const hubManagementServerUrl = getEnv('SPA_HUB_MANAGEMENT_SERVER_URL')
export const authorizationServerUrl = getEnv('SPA_AUTHORIZATION_SERVER_URL')
export const apiUrl = getEnv('SPA_API_URL')
export const amplitudeDisabled = parseBoolEnv(getEnv('SPA_AMPLITUDE_DISABLED'))
export const envLinks = getEnv('SPA_LINKS_CONFIG')
export const envLinkTgAuth = getEnv('SPA_LINK_TG_AUTH')
export const MAX_NESTING_LEVEL = 6

export const DEFAULT_FETCH_LIMIT = 40
export const omniNotificationsAuthenticateTimeout = 5000

function getEnv(name: string): string {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const value: null | undefined | string = (globalThis as any)[name]

  if (value == null) {
    console.warn(`\`${name}\` environment variable is not defined`)
    return ''
  }

  return value
}

function parseBoolEnv(value: string | null): boolean | null {
  switch (value) {
    case 'true':
      return true
    case 'false':
      return false
    default:
      return null
  }
}

export const keycloakClient = Keycloak({
  clientId,
  realm,
  url: keycloakUrl,
})

type SPA_LINKS_CONFIG = {
  sm: string
  cm: string
  em: string
  gt: string
  t: string
  lk: string
  fm: string
  gs: string
  chm: string
  ism: string
}

export type SPA_LINKS_MODULE_ID_CONFIG = {
  ADMIN_CONSOLE?: string
  CHECKLIST_MANAGEMENT?: string
  GRAND_TENDER?: string
  GSTATION?: string
  ISSUE_MANAGEMENT?: string
}

const keyMapping: Record<
  keyof SPA_LINKS_CONFIG,
  keyof SPA_LINKS_MODULE_ID_CONFIG | undefined
> = {
  ism: 'ISSUE_MANAGEMENT',
  chm: 'CHECKLIST_MANAGEMENT',
  gt: 'GRAND_TENDER',
  gs: 'GSTATION',
  sm: undefined,
  cm: undefined,
  em: undefined,
  t: undefined,
  lk: undefined,
  fm: undefined,
}

export const envModuleLinks = transformConfig(JSON.parse(envLinks))

function transformConfig(config: SPA_LINKS_CONFIG): SPA_LINKS_MODULE_ID_CONFIG {
  const newConfig: Partial<SPA_LINKS_MODULE_ID_CONFIG> = {}

  Object.keys(config).forEach(key => {
    const oldKey = key as keyof SPA_LINKS_CONFIG
    const newKey = keyMapping[oldKey]

    if (newKey) {
      newConfig[newKey] = config[oldKey]
    }
  })

  return newConfig as SPA_LINKS_MODULE_ID_CONFIG
}
