import { FormattedSubscription, statusColors } from '../../../common'

import {
  Attribute,
  AttributeLabel,
  AttributeStatus,
  AttributeStatuses,
  AttributeValue,
  AttributeValueBlock,
  AttributeValueBlocks,
  AttributesContainer,
} from './Attributes.styled'

type AttributesProps = {
  formattedSubscription: FormattedSubscription | null
}

export const Attributes = ({ formattedSubscription }: AttributesProps) => (
  <AttributesContainer>
    {formattedSubscription?.attrStatuses &&
      formattedSubscription?.attrStatuses.length > 0 && (
        <Attribute>
          <AttributeLabel>Статус:</AttributeLabel>
          <AttributeStatuses>
            {formattedSubscription.attrStatuses.map((status, index) => (
              <AttributeStatus
                key={status.value}
                separatorColor={statusColors[status.value]}
              >
                {status.valueLabel}
              </AttributeStatus>
            ))}
          </AttributeStatuses>
        </Attribute>
      )}

    {formattedSubscription?.attrValues &&
      formattedSubscription?.attrValues.length > 0 && (
        <Attribute>
          <AttributeLabel>Атрибуты:</AttributeLabel>
          <AttributeValueBlocks>
            {formattedSubscription?.attrValues.map(attr => (
              <AttributeValueBlock key={attr}>{attr}</AttributeValueBlock>
            ))}
          </AttributeValueBlocks>
        </Attribute>
      )}

    {formattedSubscription?.filterCreatedAt && (
      <Attribute>
        <AttributeLabel>Дата создания:</AttributeLabel>
        <AttributeValue>
          {formattedSubscription?.filterCreatedAt?.valueLabel}
        </AttributeValue>
      </Attribute>
    )}

    {formattedSubscription?.attrApprovers &&
      formattedSubscription?.attrApprovers.length > 0 && (
        <Attribute>
          <AttributeLabel>Назначено на:</AttributeLabel>
          <AttributeValue>
            {formattedSubscription?.attrApprovers.join(', ')}
          </AttributeValue>
        </Attribute>
      )}
  </AttributesContainer>
)
